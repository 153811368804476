<template>
<!--  -->
  <div class="w-100 clearfix">
    <div class="container-fluid py-5" v-if="$store.state.cartItems.length>0">
      <div class="row">
        <div class="container">
          <div class="form-row">
            <div class="col-12 col-md-8 mb-3">
              <div class="col-12 rounded-lg bg-white">
                <div class="w-100 py-3 border-bottom">
                  <b>{{$t('cartSummary')}}</b>
                </div>

                <div class="form-row py-2 border-bottom"
                v-for="(item,index) in $store.state.cartItems" :key="'cartRow'+index">
                  <div class="col-6 col-sm-3 py-2">
                    <b-img-lazy :src="item.image" />
                  </div>
                  <div class="col py-1">
                    <div class="w-100 py-1">
                      <b>{{item.name}}</b>
                    </div>
                    <div class="w-100">
                      <span class="text-muted">{{item.category}}</span>
                    </div>
                    <div class="form-row">
                      <div class="col-auto py-1">
                        <b-form-spinbutton size="sm" v-model="item.qty"
                                           @change="updateQty(item)"
                                           style="width: 120px;"
                                           min="1" max="100"/>
                      </div>
                      <div class="col-auto py-1">
                        <b-button variant="danger" size="sm" @click="removeFromCart(item)">
                          <b-icon-trash />
                        </b-button>
                      </div>
                      <div class="col py-1 my-auto text-right">
                        <b class="text-info">{{$helper.convertToCurrency(item.realPrice * item.qty)}}</b>
                      </div>
                    </div>
                  </div>


                </div>


              </div>
            </div>

            <div class="col-12 col-md-4">

              <div class="col-12 rounded-lg bg-white mb-3">
                <div class="form-row py-3">
                  <div class="col">
                    <b>{{$t('login')}}</b>
                    <div></div>
                    <b class="text-success"
                          v-if="$store.state.loggedIn">{{$store.state.loggedName}}</b>
                    <a class="text-danger cursor" @click="$bvModal.show('loginModal')" v-else>
                      {{$t('pleaseLoginToPlaceOrder')}}
                    </a>
                  </div>
                  <div class="col-auto my-auto" v-if="$store.state.loggedIn">
                    <b-icon-check-circle variant="success" font-scale="1.3" />
                  </div>
                </div>
              </div>


              <div class="col-12 rounded-lg bg-white mb-3">

                <div class="w-100 py-2 border-bottom">
                  <b>{{$t('deliveryAddress')}}</b>
                </div>


                <div class="form-row py-3">
                  <div class="col">
                    {{address.firstName}} {{address.lastName}}
                    <div class="w-100"></div>
                    {{address.street}} {{address.houseNumber}}
                    <div class="w-100"></div>
                    {{address.postalCode}} {{address.city}}
                    <div class="w-100"></div>
                    {{address.countryName}}
                    <div class="w-100"></div>
                    {{address.phoneNumber}}
                  </div>
                  <div class="col-auto my-auto">
                    <a class="cursor" @click="$bvModal.show('deliveryAddressModal')">
                      <b-icon-pencil variant="secondary" font-scale="1.3" />
                    </a>
                  </div>
                </div>
              </div>


              <div class="col-12 rounded-lg bg-white mb-3 d-none">
                <div class="w-100 py-2 border-bottom">
                  <b>{{$t('applyVoucher')}}</b>
                </div>
                <div class="form-row">
                  <div class="col py-2">
                    <b-input-group>
                      <b-form-input
                          style="text-transform: uppercase"
                          :disabled="voucher.applied || voucher.state==='loading'"
                          v-model="voucher.code" autocomplete="off" />
                      <template #append v-if="voucher.applied">
                        <b-input-group-text >{{$helper.convertToCurrency(realVoucherAmount)}}</b-input-group-text>
                      </template>

                    </b-input-group>

                    <span class="text-danger">{{voucher.message}}</span>
                  </div>
                  <div class="col-auto py-2">
                    <b-button variant="danger" @click="removeVoucher"
                              v-if="voucher.applied">
                      <b-icon-trash />
                    </b-button>
                    <b-button v-else
                              @click="applyVoucher"
                              :disabled="voucher.state==='loading'">
                      <b-spinner v-if="voucher.state==='loading'" small class="float-left mt-1 mr-2" />
                      {{$t('apply')}}
                    </b-button>
                  </div>
                </div>
              </div>

              <div class="col-12 rounded-lg bg-white mb-3">
                <div class="w-100 py-2 border-bottom">
                  <b>{{$t('summary')}}</b>
                </div>

                <div class="form-row">
                  <div class="col py-2">{{$t('totalItems')}}</div>
                  <div class="col-auto py-2">
                    <b>{{$store.state.cartItems.length}}</b>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col py-2">{{$t('itemPrice')}}</div>
                  <div class="col-auto py-2">
                    <b>{{$helper.convertToCurrency(cartTotal)}}</b>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col py-2">{{$t('deliveryPrice')}}</div>
                  <div class="col-auto py-2">
                    <b>{{$helper.convertToCurrency(deliveryPrice)}}</b>
                  </div>
                </div>
                <div class="form-row" v-if="discountAmount>0">
                  <div class="col py-2">{{discountName}} ({{discountPercentage}}%)</div>
                  <div class="col-auto py-2">
                    <b>-{{$helper.convertToCurrency(discountAmount)}}</b>
                  </div>
                </div>

                <div class="form-row text-danger" v-if="voucherAmount>0">
                  <div class="col py-2">{{$t('voucher')}}</div>
                  <div class="col-auto py-2">
                    <b>-{{$helper.convertToCurrency(realVoucherAmount)}}</b>
                  </div>
                </div>


                <div class="form-row">
                  <div class="col py-2">{{$t('totalPrice')}}</div>
                  <div class="col-auto py-2">
                    <b>{{$helper.convertToCurrency(deliveryPrice-discountAmount-realVoucherAmount+cartTotal)}}</b>
                  </div>
                </div>


                <div class="form-row border-top">
                  <div class="col-12 text-center py-2">
                    <b-button @click="continueNext" variant="yellow">
                      <b-spinner v-if="saving" small class="float-left mr-2 mt-1"></b-spinner>
                      {{$t('placeOrder')}}
                    </b-button>

                    <b-alert :show="error" class="mb-0 mt-2" variant="danger">
                      {{message}}
                    </b-alert>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container-fluid py-5">
      <div class="row">
        <error-div :message="$t('emptyCart')" />
      </div>
    </div>

    <delivery-address-modal @added="addressAdded" />

  </div>
</template>

<script>
import ErrorDiv from "@/components/Template/ErrorDiv";
import DeliveryAddressModal from "@/components/Shop/DeliveryAddressModal";
export default {
  name: "ShopCheckOut",
  components: {DeliveryAddressModal, ErrorDiv},
  computed: {
    discountName() {
      return (this.$store.state.cartItems[0]===undefined) ? '' : this.$store.state.cartItems[0].offer.name;
    },
    discountPercentage() {
      return (this.$store.state.cartItems[0]===undefined) ? '' : this.$store.state.cartItems[0].offer.value;
    },
    cartTotal() {
      let total = 0;
      this.$store.state.cartItems.forEach((item) => {
        total += parseFloat(item.realPrice) * parseInt(item.qty);
      });
      return total;
    },
    cartDiscountTotal() {
      let total = 0;
      this.$store.state.cartItems.forEach((item) => {
        total += parseFloat(item.price) * parseInt(item.qty);
      });
      return total;
    },
    discountAmount() {
      return this.cartTotal-this.cartDiscountTotal;
    },
    realVoucherAmount() {
      const amount = this.cartTotal-this.discountAmount;
      return (this.voucherAmount>amount) ? amount : this.voucherAmount;
    }
  },
  methods : {
    addressAdded(data,price) {
      this.deliveryPrice = price;
      this.address.firstName = data.firstName;
      this.address.lastName = data.lastName;
      this.address.country = data.country;
      this.address.phoneNumber = data.phoneNumber;
      this.address.street = data.street;
      this.address.houseNumber = data.houseNumber;
      this.address.city = data.city;
      this.address.postalCode = data.postalCode;
      this.address.countryName = data.countryName;
    },
    continueNext() {
      if(this.$store.state.loggedIn) {
        this.placeOrder();
      } else {
        this.$bvToast.toast(this.$t('pleaseLoginToPlaceOrder'), {
          title: this.$t("login"),
          variant : "danger",
          toaster: "b-toaster-bottom-center",
          autoHideDelay: 3000,
          appendToast: false
        });
      }
    },
    placeOrder() {
      this.saving = true;
      this.error = false;
      this.$http.post('front/placeOrder',{
        address : this.address,
        articles : this.$store.state.cartItems,
        deliveryPrice : this.deliveryPrice,
        voucher : {
          code : this.voucherCode,
          amount : this.voucherAmount
        }
      }).then(response=>{
        if(response.data.error===undefined) {
          this.saving = false;
          this.error = true;
          this.message = this.$t("errors.unExpectedError");
        } else if(response.data.error) {
          this.saving = false;
          this.error = true;
          this.message = response.data.message;
        } else {
          window.location = response.data.url;
          this.$store.commit("clearCart");
        }
      }).catch(()=>{
        this.saving = false;
        this.error = true;
        this.message = this.$t("errors.unExpectedError");
      });
    },
    removeFromCart(item) {
      this.$store.commit("removeFromCart",item);
      window.scrollTo(0,0);
    },
    updateQty(item) {
      this.$store.commit("updateCartQty",item);
    },
    removeVoucher() {
      this.voucherCode = "";
      this.voucherAmount = 0;
      this.voucher.applied = false;
      this.voucher.code = "";
    },
    async applyVoucher() {
      this.voucherCode = "";
      this.voucherAmount = 0;
      this.voucher.state = 'loading';
      this.voucher.message = "";
      this.voucher.applied = false;
      const validate = await this.$parent.$parent.checkVoucher(this.voucher.code);
      if(validate.status) {
        this.voucher.state = 'success';
        this.voucherAmount = validate.amount;
        this.voucherCode = this.voucher.code;
        this.voucher.applied = true;
      } else {
        this.voucher.state = 'error';
        this.voucher.message = validate.message;
      }
    },
  },
  data() {
    return {
      voucher : {
        state : "",
        message : "",
        code : "",
        applied : false
      },
      voucherCode : "",
      voucherAmount : 0,
      deliveryPrice : 0,
      address : {
        firstName : "",
        lastName : "",
        country : "",
        phoneNumber : "",
        street : "",
        houseNumber : "",
        city : "",
        postalCode : "",
        countryName : ""
      },
      saving : false,
      error : false,
      message : ""
    }
  }
}
</script>

<style scoped>

</style>
