<template>
  <b-modal id="deliveryAddressModal"
  :title="$t('deliveryAddress')" centered
  :hide-header-close="saving"
  :no-close-on-backdrop="saving"
  :no-close-on-esc="saving">

    <div class="form-row">
      <b-form-group class="col-12 col-sm-6" :label="$t('firstName')">
        <b-form-input v-model="address.firstName" />
      </b-form-group>

      <b-form-group class="col-12 col-sm-6" :label="$t('lastName')">
        <b-form-input v-model="address.lastName" />
      </b-form-group>

      <b-form-group class="col-12 col-sm-6" :label="$t('country')">
        <country-select className="form-control"
                        :country="address.country"
                        :autocomplete="true"
                        v-model="address.country"
                        :usei18n="false"/>
      </b-form-group>

      <b-form-group class="col-12 col-sm-6" :label="$t('phoneNumber')">
        <b-form-input v-model="address.phoneNumber"
                      placeholder="z.b : 0987654321" />
      </b-form-group>

      <b-form-group class="col-12 col-sm-8" :label="$t('street')">
        <b-form-input v-model="address.street" />
      </b-form-group>
      <b-form-group class="col-12 col-sm-4" :label="$t('doorNumber')">
        <b-form-input v-model="address.houseNumber" />
      </b-form-group>

      <b-form-group class="col-12 col-sm-8" :label="$t('city')">
        <b-form-input v-model="address.city" />
      </b-form-group>
      <b-form-group class="col-12 col-sm-4" :label="$t('postalCode')">
        <b-form-input v-model="address.postalCode" />
      </b-form-group>
    </div>


    <b-alert :show="error" class="m-0" variant="danger">
      {{message}}
    </b-alert>

    <template #modal-footer>
      <b-button v-if="!saving" variant="danger" @click="$bvModal.hide('deliveryAddressModal')">{{$t('close')}}</b-button>
      <b-button variant="yellow" :disabled="saving" @click="saveAddress">
        <b-spinner v-if="saving" small class="float-left mr-2 mt-1"></b-spinner>
        {{$t('continue')}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "DeliveryAddressModal",
  methods : {
    saveAddress() {
      this.saving = true;
      this.error = false;
      this.$http.post("front/validateDeliveryAddress",this.address).then(response=>{
        if(response.data.error===undefined) {
          this.saving = false;
          this.error = true;
          this.message = this.$t("errors.unExpectedError");
        } else if(response.data.error) {
          this.saving = false;
          this.error = true;
          this.message = response.data.message;
        } else {
          const price = response.data.price;
          this.address.countryName = response.data.countryName;
          this.$emit("added",this.address,price);
          this.$bvModal.hide("deliveryAddressModal");
        }
      }).catch(()=>{
        this.error = true;
        this.message = this.$t("errors.unExpectedError");
      }).finally(()=>{
        this.saving = false;
      });
    },
  },
  data() {
    return {
      saving : false,
      error : false,
      message : "",
      address : {
        firstName : "",
        lastName : "",
        country : "",
        phoneNumber : "",
        street : "",
        houseNumber : "",
        city : "",
        postalCode : "",
        countryName : "",
      }
    }
  }
}
</script>

<style scoped>

</style>